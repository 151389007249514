import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { createContext, useCallback, useContext, useMemo, useState } from "react";

interface OpenParams {
  title: string;
  description: string | React.ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmationLabel?: string;
  cancelLabel?: string;
  isConfirmationDisabled?: boolean;
  isCancelDisabled?: boolean;
}

interface ConfirmationModalContextType {
  open: (config: OpenParams) => void;
}

export const ConfirmationModalContext = createContext<ConfirmationModalContextType>({
  open: () => {},
});

export function useConfirmationModal() {
  if (!ConfirmationModalContext) {
    throw new Error("useConfirmationModal must be used within a ConfirmationModalProvider");
  }

  return useContext(ConfirmationModalContext);
}

interface ConfirmationModalState {
  title?: string;
  description?: string | React.ReactNode;
  isConfirmationDisabled?: boolean;
  isCancelDisabled?: boolean;
  confirmationLabel?: string;
  cancelLabel?: string;
  onConfirm?: () => Promise<void> | void;
  onCancel?: () => void;
}

export default function ConfirmationModalProvider({ children }: Readonly<{ children: React.ReactNode }>) {
  const [modalConfig, setModalConfig] = useState<ConfirmationModalState>();
  const [isConfirming, setIsConfirming] = useState(false);
  const [open, setOpen] = useState(false);

  const onOpen = useCallback(
    ({
      title,
      description,
      onConfirm,
      onCancel,
      confirmationLabel,
      cancelLabel,
      isConfirmationDisabled,
      isCancelDisabled,
    }: OpenParams) => {
      setModalConfig({
        title,
        description,
        onConfirm,
        onCancel,
        confirmationLabel: confirmationLabel ?? "Confirm",
        cancelLabel: cancelLabel ?? "Cancel",
        isConfirmationDisabled: isConfirmationDisabled || false,
        isCancelDisabled: isCancelDisabled || false,
      });
      setOpen(true);
      setIsConfirming(false);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    if (typeof modalConfig?.onCancel === "function") {
      modalConfig.onCancel();
    }
    setModalConfig(undefined);
  }, [modalConfig]);

  const handleConfirm = useCallback(async () => {
    if (typeof modalConfig?.onConfirm === "function") {
      setIsConfirming(true);
      await modalConfig.onConfirm();
      setIsConfirming(false);
    }
    handleClose();
  }, [handleClose, modalConfig]);

  const contextValue = useMemo(() => ({ open: onOpen }), [onOpen]);

  return (
    <ConfirmationModalContext.Provider value={contextValue}>
      {children}

      {open && (
        <Dialog
          open
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          sx={{
            "& .MuiDialog-paper": {
              width: "401px",
            },
          }}>
          <DialogTitle
            variant="body1"
            id="responsive-dialog-title"
            textTransform="unset"
            whiteSpace="break-spaces"
            fontWeight={700}>
            {modalConfig?.title || "Are you sure?"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}>
            <CloseIcon />
          </IconButton>

          <DialogContent>
            {typeof modalConfig?.description === "string" ? (
              <DialogContentText variant="body2" color="text.secondary">
                {modalConfig.description}
              </DialogContentText>
            ) : (
              modalConfig?.description
            )}
          </DialogContent>

          <DialogActions
            sx={{
              justifyContent: "space-between",
              mx: 1,
            }}>
            <Button onClick={handleClose} color="error" disabled={modalConfig?.isCancelDisabled}>
              {modalConfig?.cancelLabel || "Cancel"}
            </Button>

            <LoadingButton
              autoFocus
              onClick={handleConfirm}
              variant="contained"
              color="componentNewEmerald50"
              loading={isConfirming}
              disabled={modalConfig?.isConfirmationDisabled || isConfirming}
              disableElevation>
              {modalConfig?.confirmationLabel || "Confirm"}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </ConfirmationModalContext.Provider>
  );
}
