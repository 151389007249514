import { Checkbox, Divider, FormControlLabel, Grid, Typography } from "@mui/material";
import { useConfirmationModal } from "contexts/confirmationModalContext";
import { useSnackBar } from "contexts/snackBarContext";
import { useState } from "react";
import { cancelDraft, cancelRequest } from "../../../services/requestService";
import { requestStore } from "../../../stores/modalManager";
import { store } from "../../../stores/storeManager";
import { cancellationReasons } from "../../../utils/globalConfig";
import Button from "../button";
import Footer from "../footer";
import Header from "../header";
import { ArrowLeft } from "../icons";
import Input from "../input";
import { RadioButton, RadioButtonContainer } from "../radioButton";
import Tooltip from "../tooltip";
import CreateRequest from "./createRequest";
import Request from "./request";

const cancelFactory = status => {
  if (status === "draft") return cancelDraft;
  return cancelRequest;
};

export default function Cancellation() {
  const snackBar = useSnackBar();
  const { open } = useConfirmationModal();
  const request = requestStore.useState(s => s.request);
  const {
    country: { alpha2 },
  } = store.useState(s => s);
  const [selectedReason, setSelectedReason] = useState(0);
  const [userInputReason, setUserInputReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [isSendNotification, setIsSendNotification] = useState(true);
  const hideModal = requestStore.useState(s => s.hideModal);

  const owner = request.owner || request.user;

  const showRequest = e => {
    e.preventDefault();
    requestStore.update(s => {
      s.componentsToRender = request.status === "draft" ? <CreateRequest /> : <Request />;
    });
  };

  const onClickCancel = async e => {
    e.preventDefault();
    if (showTextArea && !userInputReason) {
      setShowInfo(true);
      return;
    }

    open({
      title: "Confirm Cancellation?",
      description: "Are you sure you want to cancel the request?",
      confirmationLabel: "Okay",
      onConfirm: handleCancellation,
    });
  };

  const handleCancellation = async () => {
    try {
      setIsLoading(true);

      const cancel = cancelFactory(request.status);

      const result = await cancel(request._id, {
        reason: showTextArea ? userInputReason : cancellationReasons[alpha2][selectedReason],
        userInputReason: !!userInputReason,
        isSendNotification,
      });

      snackBar.pop({
        content: result.data.message,
      });

      hideModal();
    } catch (e) {
      snackBar.pop({
        content: e.data?.toString(),
        alertProps: { severity: "error" },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectOptions = value => {
    const isOthers = cancellationReasons[alpha2][value] === "Others";
    setShowTextArea(isOthers);
    if (!isOthers) setUserInputReason("");
    setSelectedReason(value);
  };

  const onChangeInput = value => {
    setUserInputReason(value);
    setShowInfo(false);
  };

  const onChangeSendNotification = () => setIsSendNotification(!isSendNotification);

  return (
    <div className="modalContent cancellationUM">
      <Header>
        <ArrowLeft onClick={showRequest} width="24" height="24" />
        <div className="title">Cancellation</div>
      </Header>
      <div className="content">
        <RadioButtonContainer value={selectedReason} onChange={onSelectOptions}>
          {cancellationReasons[alpha2].map((reason, i) => (
            <RadioButton id={i} key={i}>
              <div>{reason}</div>
            </RadioButton>
          ))}
        </RadioButtonContainer>
        {showTextArea && (
          <Input
            placeholder="i.e Visiting a clinic instead, recovering, etc."
            value={userInputReason}
            onChange={onChangeInput}
            noBorder
            isMultiLine
            autoSize
            maxRows={50}
            counter
            maxLength={300}
            info={<Tooltip id="tooltip" />}
            showInfo={showInfo}
          />
        )}
      </div>
      <Footer>
        <Divider />
        <Grid container p={2} justifyContent="space-between" alignItems="center">
          <FormControlLabel
            sx={{ "&.Mui-disabled": { cursor: "not-allowed" } }}
            control={
              <Checkbox
                color="secondary"
                checked={isSendNotification}
                onChange={onChangeSendNotification}
                disabled={!owner.isAppUser}
                sx={{ "&.Mui-disabled": { color: "#E0E2E4" } }}
              />
            }
            label={
              <>
                <Typography component="div" variant="caption" fontWeight={600} color="#2C2C2C">
                  Notify user on booking cancellation
                </Typography>
                <Typography component="div" variant="caption" color="#8496A6">
                  In-app notification
                </Typography>
              </>
            }
          />
          <Button
            text={isLoading ? "Please wait..." : "Confirm Cancellation"}
            type="button"
            className="primary"
            disabled={isLoading}
            onClick={onClickCancel}
          />
        </Grid>
      </Footer>
    </div>
  );
}
